<template>
  <vx-card>
    <div class="grid grid-cols-12 gap-2">
      <div class="col-span-12">
        <fieldset class="p-2 pt-0 border border-grey">
          <legend class="px-1 font-bol"> {{ $t('filtros') }} </legend>
          <div class="flex items-center p-2 flex-wrap gap-4">
            <select-suggestion
              :class="`flex-grow w-full ${invalidQuestionnaire ? 'required' : ''}`"
              model="ProctoringConfigQuestionnaire"
              :label="$t('Questionnaire')"
              :max="10"
              :itemTextFormat="(model) => model.questionnaire.name"
              color="dark"
              v-model="filter.config"
              :appendClearOption="true"
              ref="select_questionnaire"
              :promptText="$t('informe-o-nome-para-pesquisar')"
              :placeholderText="$t('digite-o-nome-do-questionario')"/>
            <!-- <label class="font-medium text-grey"> USUÁRIO </label> -->
            <select-suggestion
              v-if="false"
              :class="invalidQuestionnaire ? 'required flex-grow' : 'flex-grow'"
              column="name,email"
              model="User"
              color="dark"
              :label="$t('usuario')"
              v-model="filter.user"
              :appendClearOption="true"
              ref="select_questionnaire"
              :placeholderText="$t('digite-o-nome-ou-email-do-usuario')">
            </select-suggestion>
            <vs-select
                ref="userSelect"
                class="flex-grow"
                v-if="userRepository"
                :placeholder="$t('selecione-usuarios-para-filtrar')"
                autocomplete
                :label="$t('usuario')"
                v-model="filter.user">
                <vs-select-item :key="index" :value="item.id" :text="`${item.name} <br/><div class=\'font-bold text-sm\'>${item.email}<div>`" v-for="(item,index) in userRepository.objects" />
              </vs-select>
            <vs-select :label="$t('processamento-de-video')" v-model="filter.processStatus">
              <vs-select-item text="Nenhum" :value="null"/>
              <vs-select-item text="Não contém vídeo" value="N"/>
              <vs-select-item text="Não Iniciado" value="P"/>
              <vs-select-item text="Em Andamento" value="I"/>
              <vs-select-item text="Completo" value="F"/>
            </vs-select>
            <div class="grid">
              <label class="col-span-3 p- font-medium text-grey"> {{ $t('criticidade-dos-eventos') }} </label>
              <div class="flex gap-2 flex-grow-0">
                <div @click="filter.severity='L'" :class="severityFilterVisuals('L', 'black')">
                  <font-awesome-icon icon="exclamation" class="h-4 w-4 text-success"/>
                  <span class="text-success">{{ $t('baixa') }}</span>
                </div>
                <div @click="filter.severity='M'" :class="severityFilterVisuals('M', 'black')">
                  <font-awesome-icon icon="exclamation-circle" class="h-4 w-4 text-warning"/>
                  <span class="text-warning">{{ $t('base_question.difficulty.M') }}</span>
                </div>
                <div @click="filter.severity='H'" :class="severityFilterVisuals('H', 'black')">
                  <font-awesome-icon icon="exclamation-triangle" class="h-4 w-4 text-danger"/>
                  <span class="text-danger">{{ $t('alta') }}</span>
                </div>
              </div>
            </div>
            <div class="flex gap-1 ml-auto">
              <vs-checkbox class="text-sm smaller-checkbox" v-model="autoFilter">{{ $t('automatico') }}</vs-checkbox>
              <vs-button :disabled="!filter.config" class="py-auto px-4" @click="reloadGrid">{{ $t('filtrar') }}</vs-button>
              <vs-button class="py-auto px-4" type="border" :disabled="!isFiltering" @click="clearFilters">{{ $t('action.clear') }}</vs-button>
            </div>
          </div>

        </fieldset>
      </div>

      <div class="col-span-12" v-show="filter.config">
        <Grid
          :service="service"
          route_name="proctoring_config"
          order_column="updated_at"
          ref="grid"
          :hide_searchbar="true"
          route_grid_path="/classroom_user/grid"
        >
          <template v-slot:gridThead>
            <vs-th class="w-64 sm:w-64 md:w-72 lg:w-80 xl:w-96" key="user"> <!-- Foto -->
              {{ $t('usuario') }}
            </vs-th>
            <vs-th key="started_at"> <!-- Data Início -->
              {{ $t('data-inicio') }}
            </vs-th>
            <vs-th key="finished_at"> <!-- Data Fim -->
              {{ $t('data-fim') }}
            </vs-th>
            <vs-th key="events"> <!-- Eventos -->
              {{ $t('eventos') }}
            </vs-th>
            <vs-th key="video_progress"> <!-- Vídeo Gravado -->
              {{ $t('video') }}
            </vs-th>
          </template>

          <template v-slot:filterZone>

          </template>

          <template v-slot:gridActions>
            <vs-th key="actions">{{ $t('Actions') }}</vs-th>
          </template>

          <!--  -->

          <template v-slot:gridData="props">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data">
              <vs-td key="user"> <!-- Usuário -->
                <div class="grid w-64 sm:w-64 md:w-72 lg:w-80 xl:w-96 gap-x-2 items-center justify-start">
                  <vs-avatar class="m-0 pl-0 col-start-1 row-span-2" :src="tr.user.profile_picture"/>

                  <span
                    v-tooltip.auto="{
                      content: tr.user.name,
                      delay: { show: 100, hide: 100 }
                    }"
                    class="truncate block col-start-2 row-start-1">
                    {{tr.user.name}}
                  </span>

                  <span
                    v-tooltip.auto="{
                      content: tr.user.email,
                      delay: { show: 100, hide: 100 }
                    }"
                    class="truncate block col-start-2 row-start-2">
                    {{tr.user.email}}
                  </span>
                </div>

              </vs-td>

              <vs-td key="started_at"> <!-- Data Início -->
                {{$utils.format.date(tr.created_at)}}
              </vs-td>

              <vs-td key="finished_at"> <!-- Data Fim -->
                {{$utils.format.date(tr.finished_at)}}
              </vs-td>

              <vs-td key="events"> <!-- Eventos -->
                <div class="flex flex-col gap-0">
                  <div
                    v-if="tr.event_count && tr.event_count.L > 0"
                    class="text-success flex flex-row justify-between gap-1">
                    <label>{{ $t('baixo') }}</label>
                    <label>{{ tr.event_count.L }}</label>
                  </div>
                  <div
                    v-if="tr.event_count && tr.event_count.M > 0"
                    class="text-warning flex flex-row justify-between gap-1">
                    <label>{{ $t('medio') }}</label>
                    <label>{{ tr.event_count.M }}</label>
                  </div>
                  <div
                    v-if="tr.event_count && tr.event_count.H > 0"
                    class="text-danger flex flex-row justify-between gap-1">
                    <label>{{ $t('alto') }}</label>
                    <label>{{ tr.event_count.H }}</label>
                  </div>
                </div>
              </vs-td>

              <vs-td width="10%" key="video_progress"> <!-- Vídeo Gravado -->
                {{ videoProgressText(tr) }}
              </vs-td>

              <vs-td width="13%" key="actions"> <!-- Vídeo Gravado -->
                <div class="grid grid-cols-3 gap-8 sm:gap-4 md:gap-4">
                  <div class="flex items-center justify-center">
                    <feather-icon
                      v-permission="'proctoring.audit.view'"
                      svgClasses="h-5 w-5 mb-1 stroke-current text-primary"
                      icon="EyeIcon"
                      @click="open(tr)"
                      v-tooltip.auto="{
                        content: 'Abrir Auditoria',
                        delay: { show: 100, hide: 100 }
                      }"/>
                  </div>
                  <div class="flex items-center justify-center">
                    <feather-icon
                      v-permission="'proctoring.audit.force_process'"
                      svgClasses="h-5 w-5 mb-1 stroke-current text-primary"
                      icon="VideoIcon"
                      @click="forceProctoringProccess(tr)"
                      v-tooltip.auto="{
                        content: 'Processar vídeo',
                        delay: { show: 100, hide: 100 }
                      }"/>
                  </div>
                  <!-- -->
                  <div class="flex items-center justify-center">
                    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
                      <feather-icon
                        type="filled"
                        icon="MoreVerticalIcon">
                      </feather-icon>
                      <vs-dropdown-menu>
                        <vs-dropdown-item v-if="!tr.finished_at">
                          <div class="flex"
                            style="width: 150px;"
                            @click="videoInProcessing(tr)" v-permission="'proctoring.audit.video_in_processing'">
                            <div class="w-2/12">
                              <feather-icon
                              icon="RefreshCcwIcon"
                              svgClasses="text-warning h-5 w-5 mb-1 stroke-current"/>
                            </div>
                            <div class="w-10/12">
                              <div class="flex bg-gray-200 float-left">
                                {{ $t('action.video_in_processing') }}
                              </div>
                            </div>
                          </div>
                        </vs-dropdown-item>
                      </vs-dropdown-menu>
                    </vs-dropdown>
                  </div>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </Grid>
      </div>
    </div>
  </vx-card>
</template>

<script>
import ProctoringService from '@/services/api/ProctoringService'
import ProctoringClassroomUserList from './components/ProctoringClassroomUserList.vue'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import SelectSuggestion from '@/components/SelectSuggestion.vue'
import EduPlaceholderAction from '@/components/EduPlaceholderAction.vue'
import FeatherIcon from '@/components/FeatherIcon.vue'
import ObjectRepository from '@/models/ObjectRepository'
import ContentQuestionnaireService from '@/services/api/ContentQuestionnaireService'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ProctoringClassroomUserList,
    Datetime,
    SelectSuggestion,
    EduPlaceholderAction,
    FeatherIcon,
  },

  props: {
  },

  data: () => ({
    service: null,
    questionnaireService: null,
    classroomUsers: [],
    filter: {
      start_at: null,
      end_at: null,
      config: null,
      user: null,
      severity: null,
      processStatus: null,
    },
    autoFilter: false,
    autoFilterBounceTimer: null,
    userRepository: false,
  }),

  created() {
    this.service = ProctoringService.build(this.$vs)
    this.questionnaireService = ContentQuestionnaireService.build(this.$vs)
    this.userRepository = new ObjectRepository()
  },

  mounted() {
    const userSelect = this._.get(this, '$refs.userSelect')
    if (userSelect && 'vuesaxHacks' in this.$utils) {
      this.$utils.vuesaxHacks.vsSelectForceText(userSelect)
    }
  },

  computed: {
    config() {
      return this.filter.config
    },
    user() {
      return this.filter.user
    },
    severity() {
      return this.filter.severity
    },
    processStatus() {
      return this.filter.processStatus
    },
    isFiltering() {
      return this.filter.user || this.filter.severity || this.filter.processStatus
    }
  },

  methods: {
    open(data) {
      const routeData = this.$router.resolve({
        name: 'proctoring_audit_user_audit',
        params: { id: data.id },
      })
      window.open(routeData.href, '_blank')
    },
    reloadGrid() {
      this.$refs['grid'].fillCustomFilters([
        {key: 'severity', value: this.filter.severity || ''},
        {key: 'user', value: this.filter.user || ''},
        {key: 'config', value: (this.filter.config && this.filter.config.id) || ''},
        {key: 'process_status', value: this.filter.processStatus || ''}
      ])
      this.$refs['grid'].fetchGridData()
    },
    clearFilters() {
      this.filter.user = null
      this.filter.severity = null
    },
    debouncedReload() {
      if (this.autoFilter) {
        if (this.autoFilterBounceTimer) clearTimeout(this.autoFilterBounceTimer)
        this.autoFilterBounceTimer = setTimeout(this.reloadGrid, 500)
      }

    },
    severityFilterVisuals(filter, color = 'primary', extra = 'flex px-2 items-center cursor-pointer gap-1') {
      const borderDefinition = `border-${color} border-little-round border-shadowed`
      const colorized = this.filter.severity === filter ? `stroke-current bg-${color} ${borderDefinition}` : ''
      return [
        colorized,
        extra
      ]
    },
    videoProgressText(classroomUser) {

      if (!this.isProctoringRecord(classroomUser.record_medium)) {
        return this.$t('nao-contem-video')
      }

      const progress = classroomUser.video_progress
      if (this.isEmpty(progress)) {
        return this.$t('aguardando-processamento')
      } else if (progress >= 100) {
        return this.$t('processamento-completo')
      } else if (progress >= 0) {
        return this.$t('em-processamento')
      } else {
        return '-'
      }
    },
    updateUserRepository(questionnaireId) {
      this.questionnaireService.getParticipants(questionnaireId, 3).then(
        (response) => {
          if (response && this._.isArray(response)) {
            this.userRepository.setObjects(response)
          }
        }
      )
    },
    forceProctoringProccess(classroomUser) {
      const proctoringService = ProctoringService.build(this.$vs, process.env.VUE_APP_API_PROCTORING_BASE_URL)
      proctoringService.forceProctoringProccess(classroomUser.questionnaire_answer_id, classroomUser.classroom_id).then(
        (response) => {
          this.notifySuccess(this.$vs, this.$t('video-de-proctoring-enviado-para-fila-de-processamento'))
        }
      )
    },
    videoProgressFinished(classroomUser) {
      console.log('videoProgressFinished...', classroomUser)
      if (!this.isProctoringRecord(classroomUser.record_medium)) {
        return false
      }
      const progress = classroomUser.video_progress
      if (progress >= 100) {
        return true
      }
      return false
    },
    videoInProcessing(data){
      const proctoringService = ProctoringService.build(this.$vs, process.env.VUE_APP_API_PROCTORING_BASE_URL)
      proctoringService.videoInProcessing(data.questionnaire_answer_id, data.classroom_id).then(
        (response) => {
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
          this.reloadGrid()
        },
          error => {
            this.notifyError(this.$vs, this.$t('erro-ao-processar-esta-requisicao'))
          }
        ).finally(
          () => {
            this.$vs.loading.close()
          }
      )
    },
  },

  watch: {
    config(val) {
      const questionnaireId = this._.get(val, 'questionnaire.id', null)
      if (questionnaireId !== null) this.updateUserRepository(questionnaireId)

      this.reloadGrid()
    },
    user(val) {
      this.debouncedReload()
    },
    severity(val) {
      this.debouncedReload()
    },
    processStatus(val) {
      this.debouncedReload()
    },
    autoFilter(val) {
      if (val && this.isFiltering) this.reloadGrid()
    }
  }

}
</script>

<style lang="scss">
html.vuesax-app-is-ltr body div#app div.layout--main.main-vertical.navbar-floating.footer-static div#content-area.content-area-reduced div.content-wrapper div.router-view div.router-content div.content-area__content div.vx-card div.vx-card__collapsible-content.vs-con-loading__container div.vx-card__body div.grid.grid-cols-12 div.col-span-8 div div.vs-component.vs-con-table.vs-table-primary header.header-table.vs-table--header div.vs-row.w-full.pb-2.flex div.w-full.relative.vs-col.grid.self-end {
  width: 98% !important;
}

.border-shadowed {
  border-color: transparent !important;
  box-shadow: 0 0 0 1px $black !important;
}

.border-little-round {
  border-radius: 0.2rem !important;
}
</style>
